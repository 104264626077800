import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import BottomAdBanner from '../components/BottomAdBanner';
import SimpleButton from '../components/SimpleButton';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useTelegram } from '../hooks/useTelegram';
import './PlayAgainWait.css';
import SimpleRedButton from '../components/SimpleRedButton';
import { useTranslation } from 'react-i18next';

const PlayAgainWait: React.FC = () => {
    const telegramData = useTelegram();
    const ws = useWebSocket();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [opponentLeft, setOpponentLeft] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const [waitingText, setWaitingText] = useState(t('PlayAgainWait.waitingForOpponent'));
    const timerRef = useRef<number | null>(null);

    useEffect(() => {
        if (ws && telegramData.user) {
            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === 'start') {
                    navigate('/game', { state: data });
                }
                if (data.type === 'play-again-decline-response') {
                    setOpponentLeft(true);
                    setWaitingText(t('PlayAgainWait.opponentLeft'));
                    telegramData.gameId = undefined;
                }
            };
        }

        const startCountdown = () => {
            const startTime = Date.now();
            const tick = () => {
                const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
                const remainingTime = Math.max(30 - elapsedTime, 0);
                setCountdown(remainingTime);

                if (remainingTime > 0) {
                    timerRef.current = requestAnimationFrame(tick);
                } else {
                    setWaitingText(t('PlayAgainWait.opponentLeft'));
                    if (ws && telegramData.user) {
                        const message = {
                            type: 'play-again-timeout',
                        };
                        ws.send(JSON.stringify(message));
                    }
                }
            };
            tick();
        };

        startCountdown();

        return () => {
            if (timerRef.current) {
                cancelAnimationFrame(timerRef.current);
            }
        };
    }, [ws, telegramData.user, navigate, t]);

    const handleCancelClick = () => {
        if (ws && telegramData.user) {
            const message = {
                type: 'play-again-decline-request'
            };
            ws.send(JSON.stringify(message));
        }
        navigate('/');
        telegramData.gameId = undefined;
    };

    if (opponentLeft) {
        return (
            <div className="play-again-wait-game">
                <div className="content">
                    <div className='play-again-text-timer'>
                        <h1>{t('PlayAgainWait.opponentLeft')}</h1>
                    </div>
                    <SimpleButton text={t('PlayAgainWait.menuButton')} onClick={handleCancelClick} />
                </div>
                <BottomAdBanner />
            </div>
        );
    } else {
        return (
            <div className="play-again-wait-game">
                <div className="content">
                    <div className='play-again-text-timer'>
                        <h1>{waitingText}</h1>
                        {countdown > 0 && <p>{t('PlayAgainWait.timeRemaining', { countdown })}</p>}
                    </div>
                    <SimpleRedButton text={t('PlayAgainWait.cancelButton')} onClick={handleCancelClick} />
                </div>
            </div>
        );
    }
};

export default PlayAgainWait;
