import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

interface LanguageSwitcherProps {
    currentLanguage: string;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ currentLanguage }) => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('selectedLanguage', lng);
    };

    return (
        <div className="language-switch">
            <button
                className={currentLanguage === 'en' ? 'active' : ''}
                onClick={() => changeLanguage('en')}
            >
                ENGLISH
            </button>
            <button
                className={currentLanguage === 'ru' ? 'active' : ''}
                onClick={() => changeLanguage('ru')}
            >
                РУССКИЙ
            </button>
        </div>
    );
};

export default LanguageSwitcher;
