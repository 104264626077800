import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useTelegram } from '../hooks/useTelegram';
import { useNavigate, useLocation } from 'react-router-dom';
import SimpleButton from '../components/SimpleButton';
import './Game.css';
import SimpleRedButton from '../components/SimpleRedButton';

interface Player {
    userId: number;
    userName: string;
    mark: number;
    score: number;
}

interface Line {
    points: { x: number, y: number }[];
    mark: number;
}


interface GameResult {
    type: string;
    score: {
        player: number;
        opponent: number;
    };
    duration: number;
    totalDots: number;
    result: 'win' | 'lose' | 'tie';
}

const Game: React.FC = () => {
    const { user, gameId } = useTelegram();
    const { t } = useTranslation();
    const ws = useWebSocket();
    const location = useLocation();
    const navigate = useNavigate();
    const [stateGameId, setStateGameId] = useState<string | null>(location.state?.gameId || null);
    const [players, setPlayers] = useState<Player[]>(location.state?.players || []);
    const [gameStarted, setGameStarted] = useState<boolean>(true);
    const [showEndGamePopup, setShowEndGamePopup] = useState<boolean>(false);
    const [showAskEndGamePopup, setShowAskEndGamePopup] = useState<boolean>(false);
    const [showLeavePopup, setShowLeavePopup] = useState<boolean>(false);
    const [currentTurn, setCurrentTurn] = useState<number | null>(location.state?.currentTurn || null);
    const [field, setField] = useState<number[][]>(Array(20).fill(0).map(() => Array(20).fill(0)));
    const [lines, setLines] = useState<Line[]>([]);
    const [playerScore, setPlayerScore] = useState<number>(0);
    const [opponentScore, setOpponentScore] = useState<number>(0);
    const [opponentDisconnected, setOpponentDisconnected] = useState<boolean>(false);
    const [messageSent, setMessageSent] = useState<boolean>(false);
    const [showResultPopup, setShowResultPopup] = useState<boolean>(false);
    const [resultMessage, setResultMessage] = useState<GameResult | null>(null);
    const [countdown, setCountdown] = useState<number | null>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [reconnectTimer, setReconnectTimer] = useState<number | null>(null);
    const [showReconnectPopup, setShowReconnectPopup] = useState<boolean>(false);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const container = containerRef.current;

            const ratio = window.devicePixelRatio || 1;
            canvas.width = 600 * ratio;
            canvas.height = 600 * ratio;
            canvas.style.width = '600px';
            canvas.style.height = '600px';

            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.scale(ratio, ratio);
            }

            if (container) {
                const scrollLeft = (canvas.width / ratio - container.clientWidth) / 2;
                const scrollTop = (canvas.height / ratio - container.clientHeight) / 2;
                container.scrollLeft = scrollLeft;
                container.scrollTop = scrollTop;
            }
        }
    }, []);

    useEffect(() => {
        if (ws) {
            if (ws && user && !messageSent) {
                ws.send(JSON.stringify({
                    type: 'join',
                    userId: user.id,
                    gameId: gameId || stateGameId,
                    userName: user.first_name
                }));
                setMessageSent(true);
            }

            ws.onmessage = (event) => {
                const message = JSON.parse(event.data);

                if (message.type === 'ask-to-end-request') {
                    setShowEndGamePopup(true);
                } else if (message.type === 'ask-to-end-decline-response') {
                    setShowEndGamePopup(false);
                } else if (message.type === 'update') {
                    const { x, y, mark, currentTurn, redLines, blueLines, players } = message;

                    setField(prevField => {
                        const newField = [...prevField];
                        newField[x][y] = mark;
                        return newField;
                    });

                    setCurrentTurn(currentTurn);

                    const formattedRedLines = redLines.map((lineArray: number[][]) => ({
                        points: lineArray.map(point => ({ x: point[0], y: point[1] })),
                        mark: 1
                    }));

                    const formattedBlueLines = blueLines.map((lineArray: number[][]) => ({
                        points: lineArray.map(point => ({ x: point[0], y: point[1] })),
                        mark: 2
                    }));

                    setLines([...formattedRedLines, ...formattedBlueLines]);

                    setPlayers(players);

                    const currentUser = players.find((p: { userId: number }) => p.userId === user?.id);
                    const opponent = players.find((p: { userId: number }) => p.userId !== user?.id);

                    if (currentUser) {
                        setPlayerScore(currentUser.score);
                    }
                    if (opponent) {
                        setOpponentScore(opponent.score);
                    }

                    // if (currentTurn === user?.id) {
                    //     if (navigator.vibrate) {
                    //         navigator.vibrate(100);
                    //       }
                    // }
                } else if (message.type === 'opponent-disconnected') {
                    setOpponentDisconnected(true);
                    setReconnectTimer(30);
                    setPlayers(prevPlayers => prevPlayers.map(player =>
                        player.userId === message.userId ? { ...player, userName: `${player.userName} disconnected` } : player
                    ));
                } else if (message.type === 'opponent-rejoin') {
                    setOpponentDisconnected(false);
                    setPlayers(prevPlayers => prevPlayers.map(player =>
                        player.userId === message.userId ? { ...player, userName: message.userName } : player
                    ));
                    setShowReconnectPopup(false);
                    setReconnectTimer(null);
                } else if (message.type === 'rejoin') {
                    const { players, currentTurn, field, redLines, blueLines } = message;

                    setPlayers(players);
                    setCurrentTurn(currentTurn);
                    setField(field);

                    const formattedLines = [
                        ...redLines.map((lineArray: number[][]) => ({
                            points: lineArray.map(point => ({ x: point[0], y: point[1] })),
                            mark: 1
                        })),
                        ...blueLines.map((lineArray: number[][]) => ({
                            points: lineArray.map(point => ({ x: point[0], y: point[1] })),
                            mark: 2
                        }))
                    ];

                    setLines(formattedLines);

                    const currentUser = players.find((p: { userId: number }) => p.userId === user?.id);
                    const opponent = players.find((p: { userId: number }) => p.userId !== user?.id);

                    if (currentUser) {
                        setPlayerScore(currentUser.score);
                    }
                    if (opponent) {
                        setOpponentScore(opponent.score);
                    }

                    setGameStarted(true);
                    setShowEndGamePopup(false);
                } else if (message.type === 'game-end-win' || message.type === 'game-end-lose') {
                    setResultMessage(message);
                    setShowResultPopup(true);
                } else if (message.type === 'game-not-exist') {
                    navigate('/game-not-exist');
                }
            };
        }
    }, [ws, gameId, user, players, stateGameId, messageSent, navigate]);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (countdown !== null && countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        } else if (countdown === 0) {
            setIsButtonDisabled(false);
            setCountdown(null);
        }

        return () => clearTimeout(timer);
    }, [countdown]);

    useEffect(() => {
        let reconnectInterval: NodeJS.Timeout;

        if (opponentDisconnected && reconnectTimer && reconnectTimer > 0) {
            setShowReconnectPopup(true);
            reconnectInterval = setTimeout(() => setReconnectTimer(reconnectTimer - 1), 1000);
        } else if (reconnectTimer === 0) {
            if (ws) {
                ws.send(JSON.stringify({ type: 'player-reconnect-time-out' }));
            }
            setOpponentDisconnected(false);
            setShowReconnectPopup(false);
        }

        return () => clearTimeout(reconnectInterval);
    }, [reconnectTimer, opponentDisconnected, ws]);

    const handleResultPopupClose = () => {
        setShowResultPopup(false);
        if (resultMessage) {
            navigate('/random-end-game', { state: resultMessage });
        }
    };

    const handleEndGame = () => {
        setShowAskEndGamePopup(true);
    };

    const handleAskEndGame = () => {
        if (ws) {
            ws.send(JSON.stringify({ type: 'ask-to-end' }));
        }
        setShowAskEndGamePopup(false);
        setIsButtonDisabled(true);
        setCountdown(60);
    };

    const handleLeaveGame = () => {
        setShowLeavePopup(true);
    };

    const handleConfirmLeaveGame = () => {
        if (ws) {
            ws.send(JSON.stringify({ type: 'leave' }));
        }
        setShowLeavePopup(false);
    };

    const handlePopupYes = () => {
        if (ws) {
            ws.send(JSON.stringify({ type: 'ask-to-end-accept' }));
        }
        setShowEndGamePopup(false);
    };

    const handlePopupNo = () => {
        if (ws) {
            ws.send(JSON.stringify({ type: 'ask-to-end-decline' }));
        }
        setShowEndGamePopup(false);
    };

    const handleCellClick = (x: number, y: number) => {
        if (ws && gameStarted && currentTurn === user?.id && field[x][y] === 0) {
            ws.send(JSON.stringify({ type: 'move', x, y }));
        }
    };

    const drawGrid = (ctx: CanvasRenderingContext2D) => {
        const cellSize = 30;
        ctx.strokeStyle = '#9ab6d1';
        for (let i = 0; i <= 20; i++) {
            ctx.beginPath();
            ctx.moveTo(i * cellSize, 0);
            ctx.lineTo(i * cellSize, 600);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(0, i * cellSize);
            ctx.lineTo(600, i * cellSize);
            ctx.stroke();
        }
    };

    const drawMarks = (ctx: CanvasRenderingContext2D) => {
        const cellSize = 30;
        field.forEach((row, y) => {
            row.forEach((cell, x) => {
                if (cell !== 0) {
                    ctx.fillStyle = cell === 1 ? 'red' : 'blue';
                    ctx.beginPath();
                    ctx.arc(x * cellSize, y * cellSize, cellSize / 4, 0, 2 * Math.PI);
                    ctx.fill();
                }
            });
        });
    };

    const drawLines = (ctx: CanvasRenderingContext2D) => {
        const cellSize = 30;
        lines.forEach(line => {
            if (line.points.length > 1) {
                ctx.beginPath();
                ctx.strokeStyle = line.mark === 1 ? 'red' : 'blue';
                line.points.forEach((point, index) => {
                    const y = point.x * cellSize;
                    const x = point.y * cellSize;
                    if (index === 0) {
                        ctx.moveTo(x, y);
                    } else {
                        ctx.lineTo(x, y);
                    }
                });
                ctx.stroke();
            }
        });
    };

    const fillClosedAreas = (ctx: CanvasRenderingContext2D) => {
        const cellSize = 30;
        lines.forEach(line => {
            if (line.points.length > 2) {
                ctx.beginPath();
                ctx.fillStyle = line.mark === 1 ? 'rgba(255, 0, 0, 0.3)' : 'rgba(0, 0, 255, 0.3)';
                line.points.forEach((point, index) => {
                    const y = point.x * cellSize;
                    const x = point.y * cellSize;
                    if (index === 0) {
                        ctx.moveTo(x, y);
                    } else {
                        ctx.lineTo(x, y);
                    }
                });
                ctx.closePath();
                ctx.fill();
            }
        });
    };

    useEffect(() => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                drawGrid(ctx);
                drawMarks(ctx);
                fillClosedAreas(ctx);
                drawLines(ctx);
            }
        }
    }, [field, lines]);

    const handleCanvasClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const rect = canvas.getBoundingClientRect();
            const y = Math.round((event.clientX - rect.left) / 30);
            const x = Math.round((event.clientY - rect.top) / 30);
            handleCellClick(x, y);
        }
    };

    const getPlayerColor = (mark: number) => {
        return mark === 1 ? 'red' : 'blue';
    };

    const handlePopupCancel = () => {
        setShowAskEndGamePopup(false);
        setShowLeavePopup(false);
    };

    const getTurnText = () => {
        const isYourTurn = currentTurn === user?.id;
        const playerMark = players.find(p => p.userId === currentTurn)?.mark;
        return {
            text: isYourTurn ? t('Game.yourTurn') : t('Game.opponentTurn'),
            color: playerMark === 1 ? 'red' : 'blue',
            additionalClass: isYourTurn ? 'your-turn-text' : ''
        };
    };

    const turnInfo = getTurnText();
    const isPopupOpen = showEndGamePopup || showResultPopup || showAskEndGamePopup || showLeavePopup || showReconnectPopup;

    return (
        <div className="game-container" ref={containerRef}>
            <div className="game-header blue-background">
                <div className="player-info">
                    <h3 className="player-name" style={{ color: getPlayerColor(players.find(p => p.userId === user?.id)?.mark || 1) }}>
                        {t('Game.you')}: {user?.first_name}
                    </h3>
                    <p className='player-score'>{t('Game.score', { score: playerScore })}</p>
                </div>
                <div className="center-buttons">
                    <SimpleRedButton
                        text={isButtonDisabled ? t('Game.wait', { countdown }) : t('Game.endGame')}
                        onClick={handleEndGame}
                        disabled={isButtonDisabled}
                    />
                    <SimpleRedButton className='leave-button' text={t('Game.leave')} onClick={handleLeaveGame} />
                    <p className={`turn-text ${turnInfo.additionalClass}`} style={{ color: turnInfo.color }}>{turnInfo.text}</p>
                </div>
                <div className="opponent-info">
                    {gameStarted && players.length === 2 ? (
                        <>
                            <h3 className="opponent-name" style={{ color: getPlayerColor(players.find(p => p.userId !== user?.id)?.mark || 2) }}>
                                {players.find(p => p?.userId !== user?.id)?.userName}
                            </h3>
                            <p className='opponent-score'>{t('Game.score', { score: opponentScore })}</p>
                        </>
                    ) : null}
                </div>
            </div>

            <div className="game-canvas">
                <canvas
                    ref={canvasRef}
                    width={600}
                    height={600}
                    onClick={handleCanvasClick}
                    className='game-canvas-item'
                />
            </div>

            {isPopupOpen && <div className="popup-overlay"></div>}

            {showEndGamePopup && (
                <div className="popup">
                    <button className="close-button" onClick={handlePopupNo}></button>
                    <div className="popup-content">
                        <p>{t('Game.askToEndGame', { result: playerScore > opponentScore ? t('Game.win') : playerScore === opponentScore ? t('Game.tie') : t('Game.lose') })}</p>
                        <div className='popup-buttons-container'>
                            <SimpleRedButton text={t('Game.yes')} onClick={handlePopupYes} />
                            <SimpleButton text={t('Game.no')} onClick={handlePopupNo} />
                        </div>
                    </div>
                </div>
            )}

            {showResultPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <p>
                            {resultMessage?.result === 'win'
                                ? t('Game.youWin')
                                : resultMessage?.result === 'lose'
                                    ? t('Game.youLose')
                                    : t('Game.itsATie')}
                        </p>

                        <SimpleButton text={t('Game.okTitle')} onClick={handleResultPopupClose} />
                    </div>
                </div>
            )}

            {showAskEndGamePopup && (
                <div className="popup">
                    <button className="close-button" onClick={handlePopupCancel}></button>
                    <div className="popup-content">
                        <p>{t('Game.askEndGame', { result: playerScore > opponentScore ? t('Game.win') : playerScore === opponentScore ? t('Game.tie') : t('Game.lose') })}</p>
                        <div className='popup-buttons-container'>
                            <SimpleRedButton text={t('Game.ask')} onClick={handleAskEndGame} />
                            <SimpleButton text={t('Game.cancel')} onClick={handlePopupCancel} />
                        </div>
                    </div>
                </div>
            )}

            {showLeavePopup && (
                <div className="popup">
                    <button className="close-button" onClick={handlePopupCancel}></button>
                    <div className="popup-content">
                        <p>{t('Game.confirmLeave')}</p>
                        <div className='popup-buttons-container'>
                            <SimpleRedButton text={t('Game.leave')} onClick={handleConfirmLeaveGame} />
                            <SimpleButton text={t('Game.cancel')} onClick={handlePopupCancel} />
                        </div>
                    </div>
                </div>
            )}

            {showReconnectPopup && reconnectTimer !== null && (
                <div className="popup">
                    <div className="popup-content">
                        <p>{t('Game.waitingForReconnection')}</p>
                        <p>{t('Game.timeRemaining', { time: reconnectTimer < 10 ? `0${reconnectTimer}` : reconnectTimer })}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Game;
