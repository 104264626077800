import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface BackButtonProps {
    onClickHandler?: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onClickHandler }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const tg = window.Telegram?.WebApp;
        if (tg && tg.BackButton) {
            tg.BackButton.show();

            const handleClick = () => {
                if (onClickHandler) {
                    onClickHandler();
                } else if (window.history.length > 1) {
                    navigate(-1);
                } else {
                    tg.BackButton.hide();
                }
            };

            tg.BackButton.onClick(handleClick);

            return () => {
                tg.BackButton.hide();
                tg.BackButton.offClick(handleClick);
            };
        }
    }, [navigate]);

    return null;
};

export default BackButton;
