import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

const WebSocketContext = createContext<WebSocket | null>(null);

interface WebSocketProviderProps {
    children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
    const [ws, setWs] = useState<WebSocket | null>(null);

    const connectWebSocket = () => {
        const socket = new WebSocket('wss://tsgdotsgame.com/ws');

        socket.onopen = () => {
            console.log('WebSocket connection established');
            setWs(socket);
            socket.send(JSON.stringify({ type: 'ping' }));
        };

        socket.onclose = (event) => {
            console.log('WebSocket connection closed', event);
            setWs(null);
            setTimeout(connectWebSocket, 5000);
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        socket.onmessage = (message) => {
            const data = JSON.parse(message.data);
            if (data.type === 'pong') {
                console.log('Received pong');
            }
        };

        return socket;
    };

    useEffect(() => {
        const socket = connectWebSocket();

        const interval = setInterval(() => {
            if (socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({ type: 'ping' }));
            }
        }, 30000);

        return () => {
            clearInterval(interval);
            socket.close();
        };
    }, []);

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};
