import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../components/SimpleButton';
import { useTelegram } from '../hooks/useTelegram';
import './InitialPage.css';
import logo from '../assets/dots_logo.svg';
import logo_ru from '../assets/dots_logo_ru.svg';
import { createGame } from '../services/api';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InitialPage: React.FC = () => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const telegramData = useTelegram();
    const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language || 'en');

    useEffect(() => {
        let selectedLanguage = localStorage.getItem('selectedLanguage');

        if (!selectedLanguage) {
            let userLanguageCode = telegramData.user?.language_code;
            if (userLanguageCode !== 'en' && userLanguageCode !== 'ru') {
                userLanguageCode = 'en';
            }
            selectedLanguage = userLanguageCode;
        }

        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage);
            setCurrentLanguage(selectedLanguage);
        }

        if (telegramData.gameId) {
            navigate('/friend-wait-game');
        }
    }, [telegramData, navigate, i18n]);

    const handlePlayOnlineClick = () => {
        navigate('/random-wait-game');
    };

    const handlePlayWithFriendClick = async () => {
        try {
            const userId = telegramData.user?.id;
            if (userId) {
                const tg = window.Telegram?.WebApp;
                const { gameId } = await createGame(userId);
                const playWithMeText = t('InitialPage.playWithMeLinkText');
                tg.openTelegramLink(`https://t.me/share/url?url=https://t.me/DotsGameBot/Play?startapp=${gameId}&text=${playWithMeText}`);
                navigate('/friend-wait-game', { state: { gameId } });
            }
        } catch (error) {
            console.error('Failed to create game:', error);
        }
    };

    const handleRulesClick = () => {
        navigate('/game-rules');
    };

    const handleLeaderboardClick = () => {
        navigate('/leaderboard');
    };

    const toggleLanguage = () => {
        const newLanguage = currentLanguage === 'en' ? 'ru' : 'en';
        i18n.changeLanguage(newLanguage);
        setCurrentLanguage(newLanguage);
        localStorage.setItem('selectedLanguage', newLanguage);
    };

    return (
        <div className="initial-page">
            <div className="logo-container">
                {currentLanguage === 'en' ? (
                    <img src={logo} alt="Dots Logo" className="dots-logo" />
                ) : (
                    <img src={logo_ru} alt="Dots Logo" className="dots-logo" />
                )}
            </div>
            <div className="language-button-container">
                <button onClick={toggleLanguage} className="language-button">
                    <FontAwesomeIcon icon={faGlobe} /> {currentLanguage === 'en' ? 'RU' : 'EN'}
                </button>
            </div>
            <div className="buttons-container">
                <SimpleButton text={t('InitialPage.playOnlineButton')} onClick={handlePlayOnlineClick} />
                <SimpleButton text={t('InitialPage.playWithFriendButton')} onClick={handlePlayWithFriendClick} />
                <SimpleButton text={t('InitialPage.rulesButton')} onClick={handleRulesClick} />
                <SimpleButton text={t('InitialPage.leaderboard')} onClick={handleLeaderboardClick} />
            </div>
        </div>
    );
};

export default InitialPage;
