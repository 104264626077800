import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { WebSocketProvider } from './contexts/WebSocketContext';
import './i18n'; 

import FriendWaitGame from './pages/FriendWaitGame';
import RandomEndGame from './pages/RandomEndGame';
import Settings from './pages/Settings';
import RandomWaitGame from './pages/RandomWaitGame';
import InitialPage from './pages/InitialPage';
import Game from './pages/Game';
import Rules from './pages/Rules';
import PlayAgainWait from './pages/PlayAgainWait';
import GameNotExist from './pages/GameNotExist';
import Leaderboard from './pages/Leaderboard';

const App: React.FC = () => {
    useEffect(() => {
        const tg = window.Telegram?.WebApp;
        if (tg) {
            tg.expand();
        }
    }, []);
    
    return (
        <WebSocketProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<InitialPage />} />
                    <Route path="/game" element={<Game />} />
                    <Route path="/friend-wait-game" element={<FriendWaitGame />} />
                    <Route path="/random-end-game" element={<RandomEndGame />} />
                    <Route path="/random-wait-game" element={<RandomWaitGame />} /> 
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/game-rules" element={<Rules />} />
                    <Route path="/play-again-wait" element={<PlayAgainWait />} />
                    <Route path="/game-not-exist" element={<GameNotExist />} />
                    <Route path="/leaderboard" element={<Leaderboard />} />
                </Routes>
            </Router>
        </WebSocketProvider>
    );
};

export default App;
