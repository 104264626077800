import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';
import BottomAdBanner from '../components/BottomAdBanner';
import BackButton from '../components/BackButton';
import './Settings.css';
import CustomBackButton from '../components/CustomBackButton';

const Settings: React.FC = () => {
    const { i18n } = useTranslation();

    return (
        <div className="settings-page">
            <BackButton />
            <CustomBackButton />
            <div className="content-center">
                <LanguageSwitcher currentLanguage={i18n.language} />
            </div>
            <BottomAdBanner />
        </div>
    );
};

export default Settings;
