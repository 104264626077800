import React from 'react';
import { useNavigate } from 'react-router-dom';
import BottomAdBanner from '../components/BottomAdBanner';
import SimpleButton from '../components/SimpleButton';
import BackButton from '../components/BackButton';
import './Rules.css';
import { useTranslation } from 'react-i18next';
import CustomBackButton from '../components/CustomBackButton';

const Rules: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleOkClick = () => {
        navigate('/');
    };

    return (
        <div className="rules-container">
            <BackButton />
            <CustomBackButton />
            <div className="rules-content">
                <h1>{t('Rules.gameRulesTitle')}</h1>
                <div className="rules-section">
                    <h2>{t('Rules.gameRulesContent.gameGoal')}</h2>
                    <p>{t('Rules.gameRulesContent.goalDescription')}</p>
                </div>
                <div className="rules-section">
                    <h2>{t('Rules.gameRulesContent.gameSetup')}</h2>
                    <p>{t('Rules.gameRulesContent.gameSetupDescription')}</p>
                </div>
                <div className="rules-section rules-section-list">
                    <h2>{t('Rules.gameRulesContent.gameplay')}</h2>
                    <p>{t('Rules.gameRulesContent.gameplayDescription1')}</p>
                    <p>{t('Rules.gameRulesContent.gameplayDescription2')}</p>
                    <p>{t('Rules.gameRulesContent.gameplayDescription3')}</p>
                </div>
                <div className="rules-section">
                    <h2>{t('Rules.gameRulesContent.winning')}</h2>
                    <p>{t('Rules.gameRulesContent.winningDescription')}</p>
                </div>
            </div>
            <SimpleButton text={t('Rules.okButton')} onClick={handleOkClick} />
        </div>
    );
};

export default Rules;
