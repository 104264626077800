import React from 'react';
import './BottomAdBanner.css';

const BottomAdBanner: React.FC = () => {
    return (
        <div className="bottom-ad-banner">
            bottom ad banner
        </div>
    );
};

export default BottomAdBanner;
