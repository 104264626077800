import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleButton from '../components/SimpleButton';
import BottomAdBanner from '../components/BottomAdBanner';
import { useWebSocket } from '../contexts/WebSocketContext';
import './RandomEndGame.css';
import { useTelegram } from '../hooks/useTelegram';
import { useTranslation } from 'react-i18next';

interface GameResult {
    duration: number;
    totalDots: number;
    score: {
        player: number;
        opponent: number;
    };
    result: 'win' | 'lose' | 'tie';
    gameId: string;
    leaderboardPosition: number;
}

const RandomEndGame: React.FC = () => {
    const telegramData = useTelegram();
    const location = useLocation();
    const navigate = useNavigate();
    const ws = useWebSocket();
    const { t } = useTranslation();

    const { duration, totalDots, score, result, gameId, leaderboardPosition } = location.state as GameResult;

    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;

    const handlePlayAgain = () => {
        if (ws && telegramData.user) {
            ws.send(JSON.stringify({ type: 'play-again-request', gameId: gameId, userId: telegramData.user.id }));
            navigate('/play-again-wait');
        }
    };

    const handleGoToMenu = () => {
        if (ws && telegramData.user) {
            ws.send(JSON.stringify({ type: 'play-again-decline-request', gameId: gameId, userId: telegramData.user.id }));
        }

        telegramData.gameId = undefined;
        
        navigate('/');
    };

    return (
        <div className="random-end-game-container">
            <div className="stats">
                <h2>{t('RandomEndGame.statsTitle')}</h2>
                <div className='stats-facts'>
                    <p>{t('RandomEndGame.gameTime', { minutes, seconds })}</p>
                    <p>{t('RandomEndGame.dots', { totalDots })}</p>
                    <p>{t('RandomEndGame.leaderboardPosition', { leaderboardPosition })}</p>
                    {result === 'win' ? (
                        <p>{t('RandomEndGame.winMessage', { playerScore: score.player, opponentScore: score.opponent })}</p>
                    ) : (
                        <p>{t('RandomEndGame.loseMessage', { playerScore: score.player, opponentScore: score.opponent })}</p>
                    )}
                </div>
            </div>

            <div className='buttons-container-random-end-game'>
                <SimpleButton text={t('RandomEndGame.playAgainButton')} onClick={handlePlayAgain} />
                <SimpleButton text={t('RandomEndGame.menuButton')} onClick={handleGoToMenu} />
            </div>

        </div>
    );
};

export default RandomEndGame;
