import React from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../components/SimpleButton';
import './GameNotExist.css';

const GameNotExist: React.FC = () => {
    const navigate = useNavigate();

    const handleMenuClick = () => {
        navigate('/');
    };

    return (
        <div className="game-not-exist-container">
            <div className="content">
                <p className='game-not-exist-title'>Game does not exist</p>
                <SimpleButton text="Menu" onClick={handleMenuClick} />
            </div>
        </div>
    );
};

export default GameNotExist;
