import axios from 'axios';

const API_URL = 'https://tsgdotsgame.com'; // Замените на URL вашего сервера

interface CreateGameResponse {
    gameId: string;
}

interface LeaderboardEntry {
    playerName: string;
    score: number;
    id: number;
}

interface UserEntry extends LeaderboardEntry {
    userId: number;
    position: number;
}

interface LeaderboardResponse {
    topEntries: LeaderboardEntry[];
    userEntry?: UserEntry;
}

export const getLeaderboard = async (userId: number): Promise<LeaderboardResponse> => {
    try {
        const response = await axios.get<LeaderboardResponse>(`${API_URL}/api/leaderboard`, {
            params: { userId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching leaderboard:', error);
        throw error;
    }
};

export const createGame = async (userId: number): Promise<CreateGameResponse> => {
    try {
        const response = await axios.post<CreateGameResponse>(`${API_URL}/api/create-game`, { userId });
        return response.data;
    } catch (error) {
        console.error('Error creating game:', error);
        throw error;
    }
};
