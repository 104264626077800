import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from '../components/BackButton';
import { useTelegram } from '../hooks/useTelegram'; // Импортируем хук useTelegram
import './Leaderboard.css';
import { getLeaderboard } from '../services/api';
import CustomBackButton from '../components/CustomBackButton';
import SimpleButton from '../components/SimpleButton';
import { useNavigate } from 'react-router-dom';

interface LeaderboardEntry {
    playerName: string;
    score: number;
    id: number;
}

interface UserEntry extends LeaderboardEntry {
    userId: number;
    position: number;
}

const Leaderboard: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user } = useTelegram();
    const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
    const [userEntry, setUserEntry] = useState<UserEntry | null>(null);
    const userEntryRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const fetchLeaderboard = async () => {
            if (user?.id) {
                try {
                    const data = await getLeaderboard(user.id);
                    setLeaderboard(data.topEntries);
                    if (data.userEntry) {
                        setUserEntry(data.userEntry);
                    }
                } catch (error) {
                    console.error('Error fetching leaderboard:', error);
                }
            }
        };

        fetchLeaderboard();
    }, [user?.id]);

    useEffect(() => {
        if (userEntry && userEntryRef.current) {
            userEntryRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [userEntry]);

    const handleOkClick = () => {
        navigate('/');
    };

    return (
        <div className="leaderboard-page">
            <BackButton />
            <CustomBackButton />
            <div className="content-center">
                <h1 className="title">{t('Leaderboard.title')}</h1>
                <p className='subtitle'>{t('Leaderboard.subtitle')}</p>
                <div className="leaderboard-list">
                    {leaderboard.map((entry, index) => (
                        <div
                            key={index}
                            className={`leaderboard-entry ${entry.id === user?.id ? 'highlight' : ''}`}
                            ref={entry.id === user?.id ? userEntryRef : null}
                        >
                            <p className='leaderboard-entry-position'>{index + 1}</p>
                            <p className='leaderboard-entry-name'>{entry.playerName}</p>
                            <p className='leaderboard-entry-score'>{entry.score}</p>
                        </div>
                    ))}
                    {userEntry && userEntry.position > 50 && (
                        <>
                            <div className="leaderboard-entry ellipsis"><p className='ellipsis-title'>...</p></div>
                            <div
                                className="leaderboard-entry highlight"
                                ref={userEntryRef}
                            >
                                <p className='leaderboard-entry-position'>{userEntry.position}</p>
                                <p className='leaderboard-entry-name'>{userEntry.playerName}</p>
                                <p className='leaderboard-entry-score'>{userEntry.score}</p>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <SimpleButton text={t('Leaderboard.okButton')} onClick={handleOkClick} />
        </div>
    );
};

export default Leaderboard;
