import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from '../components/BackButton';
import BottomAdBanner from '../components/BottomAdBanner';
import { useTelegram } from '../hooks/useTelegram';
import { useWebSocket } from '../contexts/WebSocketContext';
import './RandomWaitGame.css';
import SimpleRedButton from '../components/SimpleRedButton';
import { useTranslation } from 'react-i18next';

const RandomWaitGame: React.FC = () => {
    const telegramData = useTelegram();
    const ws = useWebSocket();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (ws && telegramData.user) {
            const message = {
                type: 'play-random',
                userId: telegramData.user.id,
                userName: telegramData.user.first_name,
            };
            ws.send(JSON.stringify(message));

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === 'start') {
                    navigate('/game', { state: data });
                }
            };
        }
    }, [ws, telegramData.user, navigate]);

    const handleCancelClick = () => {
        if (ws && telegramData.user) {
            const message = {
                type: 'play-random-cancel',
                userId: telegramData.user.id,
            };
            ws.send(JSON.stringify(message));
        }
        navigate('/');
    };

    return (
        <div className="random-wait-game">
            <div className="content">
                <h1 className='random-wait-game-text'>{t('RandomWaitGame.searchingForOpponent')}</h1>
                <SimpleRedButton text={t('RandomWaitGame.cancelButton')} onClick={handleCancelClick} />
            </div>
            <BackButton onClickHandler={handleCancelClick}></BackButton>
        </div>
    );
};

export default RandomWaitGame;
