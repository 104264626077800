import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from '../components/BackButton';
import BottomAdBanner from '../components/BottomAdBanner';
import SimpleButton from '../components/SimpleButton';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useTelegram } from '../hooks/useTelegram';
import './FriendWaitGame.css';
import SimpleRedButton from '../components/SimpleRedButton';
import { useTranslation } from 'react-i18next';

const FriendWaitGame: React.FC = () => {
    const location = useLocation();
    const { gameId } = location.state || {};
    const telegramData = useTelegram();
    const ws = useWebSocket();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (ws && telegramData.user) {
            ws.send(JSON.stringify({
                type: 'join',
                userId: telegramData.user?.id,
                gameId: gameId || telegramData.gameId,
                userName: telegramData.user?.first_name
            }));

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === 'start') {
                    navigate('/game', { state: data });
                } else if (data.type === 'game-not-exist') {
                    setErrorMessage("Game does not exist");
                }
            };
        }
    }, [ws, telegramData, navigate, gameId]);

    const handleCancelClick = () => {
        if (ws && telegramData.user) {
            ws.send(JSON.stringify({
                type: 'friend-game-cancel',
                userId: telegramData.user.id,
                gameId: gameId || telegramData.gameId,
            }));
        }
        navigate('/');
    };

    const handlePlayClick = () => {
        var tg = window.Telegram?.WebApp;
        tg.initDataUnsafe.start_param = undefined;
        navigate('/');
    };

    return (
        <div className="friend-wait-game">
            <div className="content">
                {errorMessage ? (
                    <>
                        <p className="error-message">{errorMessage}</p>
                        <SimpleButton text={t('FriendWaitGame.menuButton')} onClick={handlePlayClick} />
                    </>
                ) : (
                    <>
                        <h1 className='friend-wait-text'>{t('FriendWaitGame.waitingForFriend')}</h1>
                        <SimpleRedButton text={t('FriendWaitGame.cancelButton')} onClick={handleCancelClick} />
                    </>
                )}
            </div>
            <BackButton onClickHandler={handleCancelClick}></BackButton>
        </div>
    );
}

export default FriendWaitGame;
