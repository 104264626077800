import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './CustomBackButton.css';

const CustomBackButton: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleBackClick = () => {
        navigate("/");
    };

    if (window.history.length <= 1) {
        return null;
    }

    return (
        <button 
            onClick={handleBackClick} 
            className="custom-back-button"
        >
            {t('CustomBackButton.back')}
        </button>
    );
};

export default CustomBackButton;
